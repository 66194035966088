define("discourse/plugins/discourse-characters-required/discourse/components/characters-required", ["exports", "discourse-common/utils/decorators"], function (_exports, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj, _init;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = (0, _decorators.default)("composer.missingReplyCharacters"), (_obj = {
    classNames: ["characters-required"],
    showRequired: function showRequired(missing) {
      return missing > 0;
    }
  }, (_applyDecoratedDescriptor(_obj, "showRequired", [_dec], (_init = Object.getOwnPropertyDescriptor(_obj, "showRequired"), _init = _init ? _init.value : undefined, {
    enumerable: true,
    configurable: true,
    writable: true,
    initializer: function initializer() {
      return _init;
    }
  }), _obj)), _obj)));

  _exports.default = _default;
});
Ember.TEMPLATES["javascripts/discourse/connectors/after-d-editor/show-required"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[1,[28,\"characters-required\",null,[[\"composer\"],[[24,[\"composer\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/connectors/after-d-editor/show-required"}});
define("discourse/plugins/discourse-characters-required/discourse/connectors/after-d-editor/show-required", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    shouldRender: function shouldRender(args) {
      return args && args.editorType === "composer";
    }
  };
  _exports.default = _default;
});
Ember.TEMPLATES["javascripts/components/characters-required"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showRequired\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"i18n\",[\"characters_required\"],[[\"count\"],[[24,[\"composer\",\"missingReplyCharacters\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/templates/components/characters-required"}});

